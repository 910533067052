import React from "react";
// import PDF from "../../public/resume.pdf";

const Resume = ({filename}) => {
  return (
    <div style={{overflowY:"hidden"}}>
      <object
        data={filename}
        type="application/pdf"
        style={{minHeight:"100vh", minWidth:"100vw"}}
      >
        <p className="tc white pa6 display-4">
          Your web browser doesn't have a PDF plugin. <br />
          Instead you can{" "}
          <a href={filename}>click here to download my resume.</a>
        </p>
      </object>
    </div>
  );
};

export default Resume;
