import React from 'react'
import "./App.css"
import 'react-toastify/dist/ReactToastify.css';
import Hoarding from './components/hoarding'
import OpenSource from './components/openSource'
import ContactMe from './components/contact'
import Footer from "./components/footer"
import { ToastContainer } from 'react-toastify'
import {Switch, Route, BrowserRouter as Router} from "react-router-dom";
import Experience from "./components/Experience"
import WorkExperience from './components/work';
import Knowme from './components/knowme';
import Resume from './components/resume';

import UP from "./up.png"
function App() {
  const setTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  React.useEffect(() => {
    setTop();
  }, []);
  return (
    <div style={{ backgroundColor: '#282e34', minHeight: "100vh", overflowY: "hidden", overflowX:"hidden" }}>
      <Router>
        <Switch>
          <Route exact path="/resume" render={props => <Resume filename="/resume.pdf" />}/>
          <Route exact path="/cv" render={props => <Resume filename="/cv.pdf" />}/>
          < Route path="*">
              <header className="App-header">
            <Hoarding />
            <Knowme />
            <Experience />
            <WorkExperience />
            <OpenSource />
            <ToastContainer/>
            <ContactMe />
            <Footer />
            <button title="Back to top" className="scroll" onClick={setTop}>
              <img src={UP} alt="Back to Top" />
            </button>
          </header>
          </Route>
        </Switch>
      </Router>

    </div>
  );
}

export default App;
