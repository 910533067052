import React from 'react'
import logo from "./bisakh.jpeg"
const Hoarding = () => {
    return (
        <div  className="container pa5" style={{fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)'}}>
            <div className="pa4 tc div grow" >
            <a style={{textDecoration:"none", cursor:"pointer"}} href="https://github.com/bisakhmondal" >
            <img src={logo} alt="https://github.com/bisakhmondal" title="bisakhmondal" height="200" className="br-100 pa0 ba b--white-10 bg-near-white"/>
            </a>
            </div>
            <p className="display-4  b link hover-white green"  >Hi, I'm <span className="hover-white blue">Bisakh.</span></p>
            <p className="display-4" style={{ fontFamily:"Source Sans Pro", color: 'whitesmoke', fontWeight: "bolder"}}><span className="red hover-white"> Developer </span> <span className="hover-pink">|</span> <span className="hover-white yellow"> Open Source Lover </span> <span className="hover-pink">|</span> <span className="hover-white green">Apache APISIX Committer</span></p>
            <p className="lead f3" style={{ fontWeight: "400"}}>I like to build scalable architecture on the cloud. I have hands on experience in different aspects of Backend Engineering and Cloud Native technologies. Hugely interested in Microservices, API Gateways and Service Mesh.</p>
        </div>
    )
}


export default Hoarding
