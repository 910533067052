import React from 'react'

const WorkExperience = () => {
    return (
        <>
        <div className="pa5 pb0 container" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
            <p className="f3 b db link hover-yellow">Internships</p>

        </div>
            <div className="row pa4 pt2" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
                <div className="col-sm pa3 mr2" >
                    <p className="f3 b db tc link hover-white green" > The D.E. Shaw Group</p>
                    <p className="lead f3 tc">Systems Engineering Intern</p>
                    <p className="lead f5 tc">May-2021 - July 2021</p>
                    <p  className="w-90">Developed an Auto Remediation System for DESCO alert and monitoring infrastructure.</p>
                    Tech: <code>Golang, gRPC, States Language
                    </code>
                    <div className="tc">

                        <a style={{ cursor: "pointer", textDecoration: "none" }} className="pa2 white grow dib hover-purple" href="https://www.deshaw.com/">Visit &#x21A3;</a>
                    </div>
                </div>
                <div className="col-sm  pa3 mr2" >
                    <p className="f3 tc b db link hover-white green"> Chromium</p>
                    <p className="lead tc f3">Google Summer of Code Student</p>
                    <p className="lead f5 tc">June 21 - August 2021</p>
                    <p className="w-90">Ported ChromeOS End-to-End Power Policy tests in Go based Tast framework.</p>

                    Tech: <code>
                        Golang, Python
                    </code>
                    <div className="tc" >

                        <a style={{ cursor: "pointer", textDecoration: "none" }} className="pa2 white grow dib hover-purple" href="https://summerofcode.withgoogle.com/projects/#6687401885302784">Visit GSoC &#x21A3;</a>
                    </div>
                </div>
                <div className="col-sm  pa3 mr2" >
                    <p className="f3 tc b db link hover-white green">api7.ai</p>
                    <p className="lead tc f3">Software Engineering Intern</p>
                    <p className="lead f5 tc">Oct 21 - Present</p>
                    <p className="w-90">Apache APISIX developer - working on both Control plane and Data plane side.</p>

                    Tech: <code>
                        Lua, Golang, OpenResty, K8s
                    </code>
                    <div className="tc" >

                        <a style={{ cursor: "pointer", textDecoration: "none" }} className="pa2 white grow dib hover-purple" href="http://apisix.apache.org/">Apache APISIX &#x21A3;</a>
                    </div>
                </div>
            </div>
    </>
    )
}

export default WorkExperience

