import React from 'react'

const OpenSource = () => {
    return (
        <>
            <div className="pa5 pb0 container" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
                <p className="f3 b db link hover-yellow">SOME OF MY OPEN SOURCE WORK</p>

            </div>
                <div className="row pa4 pt2" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
                    <div className="col-sm pa3 mr2" >
                        <p className="f4 b db link hover-white green" > Apache APISIX</p>
                        <p className="lead f5 w-90">Cloud native API Gateway, Ingress</p>
                        <p  className="w-90">Developed plugins for Monitoring (Datadog), Serverless upstream (AWS, Azure) and HashiCorp Vault.</p>
                        Tech: <code>Lua, OpenResty, Nginx
                        </code>
                        <div className="tc">

                            <a style={{ cursor: "pointer", textDecoration: "none" }} className="pa2 white grow dib hover-purple" href="https://github.com/apache/apisix/pulls?q=is%3Apr+author%3Abisakhmondal+">Visit APISIX &#x21A3;</a>
                        </div>
                    </div>
                    <div className="col-sm pa3 mr2" >
                        <p className="f4 b db link hover-white green" > Chromium</p>
                        <p className="lead f5 w-90">ChromeOS automated testing.</p>
                        <p  className="w-90">Contributed to <b>Tast</b> framework core and migrated numerous high perf. tests into <b>tast-tests</b>.</p>
                        Tech: <code>Golang, Python
                        </code>
                        <div className="tc">

                            <a style={{ cursor: "pointer", textDecoration: "none" }} className="pa2 white grow dib hover-purple" href="https://chromium-review.googlesource.com/q/owner:bisakhmondal00%2540gmail.com">Visit Gerrit &#x21A3;</a>
                        </div>
                    </div>
                    <div className="col-sm pa3 mr2" >
                        <p className="f4 b db link hover-white green" > Apache APISIX Dashboard</p>
                        <p className="lead f5 w-90">Dashboard for APISIX</p>
                        <p  className="w-90">Contributor of APISIX Dashboard by implementing critical features, perf improvements &#38; bugfixes.</p>
                        <div>

                        Tech: <code>Golang
                        </code>
                        </div>
                        <div className="tc">

                            <a style={{ cursor: "pointer", textDecoration: "none" }} className="pa2 white grow dib hover-purple" href="https://github.com/apache/apisix-dashboard">Visit APISIX Dashboard &#x21A3;</a>
                        </div>
                    </div>
                    <div className="col-sm  pa3 mr2" >
                        <p className="f4 b db link hover-white green"> mlpack</p>
                        <p className="lead f5 w-90">Fast C++ Machine Learning Library.</p>
                        <p className="w-90">Contributed to mlpack by coding existing ml algoritms in C++, armadillo &#38; also by bugfixes.</p>
                        <div className="">

                        Tech: <code>
                            C++
                        </code>
                        </div>
                        <div className="tc" >

                            <a style={{ cursor: "pointer", textDecoration: "none" }} className="white grow dib hover-purple" href="https://github.com/mlpack/mlpack">Visit mlpack &#x21A3;</a>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default OpenSource
