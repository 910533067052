import React from 'react'
import { toast } from "react-toastify"
const showToast = (message) => {
    toast.dark(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const ContactForm = () => {
    const [name, setName] = React.useState("");
    const [email, setemail] = React.useState("");
    const [message, setmessage] = React.useState("");
    
    const submit = async () => {
        if (name===""){
            showToast("Please fill your name 🐳")
            return;
        }
        if (email===""){
            showToast("Please fill your email 🐳")
            return;
        }else if(!validateEmail(email)){
            showToast("Please provide a proper email address😄")
            return
        }
        
        if(message===""){
            showToast("I'd be happy to hear you! Please write something🥺")
            return;
        }
        const body = {
            name: name,
            email: email,
            message: message
        }
        try {
            const rawResp = await fetch("https://us-central1-bisakh-com.cloudfunctions.net/send-email",{
                method: 'POST',
                headers: {
                  'Content-Type': 'text/plain' //to save from costly options preflight attached with each request
                },
                body: JSON.stringify(body)
              });
            
            const content = await rawResp.json();
            showToast(content.message)
            if(rawResp.status === 200){
                setName("")
                setemail("")
                setmessage("")
            }
        } catch (error) {
            showToast(error.response?.data?.message || "Something unexpected happened!!💥")
        }
    }

    return (
        <div>   
            <div className="pa4 pt1" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)', width: "100%" }}>
                <p className="f4 tc green">Send a Message</p>
                <div className="">
                    <label className="f5  mb2">Name</label>
                    <input value={name} onChange={e => setName(e.target.value)} className=" ba bg-transparent w-100 input-lg pa3 mb2 db br2 shadow-5 white bw2 white hover-b--current" placeholder="Your name" type="text" />
                </div>
                <div className="pt3">
                    <label className="f5  db mb2">Email</label>
                    <input value={email} onChange={e => setemail(e.target.value)} className=" pa3 w-100 ba bg-transparent mb2 bw2 db br2 white hover-b--current shadow-5" placeholder="Your email" type="email" />
                </div>

                <div className=" pt3">
                    <label className="f5  db mb2 ">Message </label>
                    <textarea value={message} onChange={e => setmessage(e.target.value)} placeholder="Message" className=" db bw2 w-100 border-box bg-transparent white hover-b--current   pa3 br2 mb2 shadow-5" rows="5" />
                </div>

                <div className="mt3 tc pt3 ">
                    <button className="ph3 pv3 ba br2  bg-transparent grow silver pointer f4 hover-green shadow-5  bw2 hover-b--current"  style={{ minWidth: "50%" }} onClick={submit} >
                        Send
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContactForm
