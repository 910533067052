import React from 'react'
import ContactForm from './contactForm'

const ContactMe = () => {
    return (
        <div className="container pa5" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
            <p className="f3 b db link hover-yellow">LET'S CONNECT</p>
            <div className="row">
                <div className="col-sm pa3 mr2 pl0">
                    <p className="f5 ">Let's connect, discuss and develop some awesome projects together or simply stay in touch.</p>
                    <div style={{ fontFamily: "Source Sans Pro", color: 'white' }}>

                        <p className="lead b red" > EMAIL ME</p>

                        <a className="f4 b green" href="mailto:bisakh@apache.org" style={{ fontFamily: "Source Sans Pro", textDecoration: "none" }}>bisakh [at] apache [.] org</a>

                        <p className="lead b pt3 pb3 blue" > CONNECT</p>

                        <ul className="social-network social-circle">
                            <li><a href="http://linkedin.com/in/bisakh-mondal" target="_blank" rel="noreferrer" className="icoLinkedin" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="https://github.com/bisakhmondal" target="_blank" rel="noreferrer" className="icoGithub" title="GitHub"><i className="fab fa-github"></i></a></li>
                            <li><a href="https://twitter.com/iBisakh" target="_blank" rel="noreferrer" className="icoTwitter" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="https://blog.bisakh.com/" target="_blank" rel="noreferrer" className="icoBlog" title="Blog"><i className="fa fa-blog"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm pa3 pb2 mr2">
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default ContactMe
