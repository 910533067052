import React from 'react'

const Knowme = () => {
    return (
        <>
        <div className="pa5 pb0 container" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
            <p className="f3 b db link hover-yellow ">Links</p>

        </div>
            <div className="row pa4 pt2" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
                <div className="col-sm pa3 mr2" >
                    <p className="f3 b db tc link hover-white green" >GitHub</p>
                    <p className="lead f4 tc">bisakhmondal</p>
                    <div className="tc">

                    <a style={{ cursor: "pointer", textDecoration: "none" }} className="white grow dib hover-purple" href="https://github.com/bisakhmondal">Visit &#x21A3;</a>
                    </div>
                </div>
                <div className="col-sm  pa3 mr2" >
                    <p className="f3 tc b db link hover-white green">My Blogs</p>
                    <p className="lead tc f4">blog.bisakh.com</p>
                    <div className="tc" >

                    <a style={{ cursor: "pointer", textDecoration: "none" }} className="white grow dib hover-purple" href="https://blog.bisakh.com">Visit &#x21A3;</a>                    </div>
                </div>
                <div className="col-sm pa3 mr2" >
                    <p className="f3 b db tc link hover-white green" >Chromium Gerrit</p>
                    <p className="lead f4 tc">bisakhmondal00@gmail.com</p>
                    <div className="tc">

                    <a style={{ cursor: "pointer", textDecoration: "none" }} className="white grow dib hover-purple" href="https://chromium-review.googlesource.com/q/owner:bisakhmondal00%2540gmail.com">Visit &#x21A3;</a>
                    </div>
                </div>
                <div className="col-sm  pa3 mr2" >
                    <p className="f3 tc b db link hover-white green">LinkedIn</p>
                   <p className="lead tc f4">bisakh-mondal</p>
                    <div className="tc" >

                    <a style={{ cursor: "pointer", textDecoration: "none" }} className="white grow dib hover-purple" href="https://www.linkedin.com/in/bisakh-mondal/">Visit &#x21A3;</a>                    </div>
                </div>
            </div>
    </>
    )
}

export default Knowme
