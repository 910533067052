import React from 'react'
import Backend from "./Backend.png"
import Microservices from "./Microservices.png"
import SDE from "./gen.png"
import DL from "./dl.png"
import UI from "./SDE.png"

const Experience = () => {
    return (
        <>
            <div className="pa5 pb0 container" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
                <p className="f3 b db link hover-yellow">MY WORK DOMAIN</p>

            </div>
            <div className="pa4 pt2" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
                
                <div className="row">
                    <div className="col-sm pa3 mr2" >
                        <img src={UI} alt="backend" height="25" />
                        <p className="f3 b db link hover-white green pt2" >Network Engineering</p>
                        <p className="lead f4 w-90">Experienced in <b>Data</b> Plane and <b>Control</b> Plane components</p>
                        <p>Actively contibuting to Apache APISIX and the Dashboard project - a cloud native API gateway, Ingress and Service Mesh on both the Data and Control Plane components.</p>
                        Tech: <code>Lua, OpenResty, Nginx, Go.
                        </code>
                    </div>
                    <div className="col-sm pa3 mr2" >
                        <img src={Backend} alt="backend" height="25" />
                        <p className="f3 b db link hover-white green pt2" > Backend Engineering</p>
                        <p className="lead f4 w-90">Passionate about designing <b>Scalable</b> backend architecture.</p>
                        <p>Love to spend time with bleeding edge frameworks from Apache Kafka to simple PostgreSQL database with Express.js. The thirst of exploration still ongoing. </p>
                        Tech: <code>Node JS, Databases &#38; more.
                        </code>
                    </div>
                    
                    <div className="col-sm pa3 mr2" >
                        <img src={Microservices} alt="backend" height="25" />
                        <p className="f3 b db link hover-white green pt2" > Cloud Native &#38; DevOps </p>
                        <p className="lead f4 w-90">Experienced in Containerized Solution and Kubernetes</p>
                        <p>Capable of handling production grade deployment through cloud native technologies and different cloud solutions. I can write custom K8s controller in Go.</p>
                        Tech: <code>Golang, Docker, Kubernetes, GCP, AWS.
                        </code>
                    </div>
                </div>

                <div className="row" >
                <div className="col-sm pa3 mr2" >
                        <img src={SDE} alt="backend" height="25" />
                        <p className="f3 b db link hover-white green pt2" >General Software Development</p>
                        <p className="lead f4 w-90">Proficient in <b>Golang, C++, JS.</b></p>
                        <p>For networking, multithreading Golang is my primary choice. For high performance applications, I choose C++ and for the web the choice is Javascript.</p>
                        Tech: <code>Golang, C++, JS, Java.
                        </code>
                    </div>
                    <div className=" col-sm pa3 mr2" >
                        <img src={UI} alt="backend" height="25" />
                        <p className="f3 b db link hover-white green pt2" > Frontend Development</p>
                        <p className="lead f4 w-90">Web Development &#38; Mobile App Development.</p>
                        <p>Worked on countless React Projects and built a React Native android app &#38; and published it on Play Store.</p>
                        Tech: <code>React.JS, React Native.
                        </code>
                    </div>
                    <div className=" col-sm pa3 mr2" >
                        <img src={DL} alt="backend" height="25" />
                        <p className="f3 b db link hover-white green pt2" > Deep Learning</p>
                        <p className="lead f4 w-90">Primarily like GAN, Deep DA, NLP.</p>
                        <p>I have worked on several projects involving adversarial networks, domain adaptaions image classification and natural language processing. Sometime I like exploring recent publications.</p>
                        Tech: <code> Python, PyTorch, TensorFlow
                        </code>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Experience
