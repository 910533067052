import React from 'react'

const Footer = () => {
    return (
        <div  className="pa3" style={{ fontFamily: "Epilogue", color: 'rgba(255,255,255,.6)' }}>
            <div className="row">
                <div className="col-sm pa3 mr2">
                <p className="f5 tl">&#xa9; {new Date().getFullYear()} All rights reserved.</p>
                </div>
                <div className="col-sm pa3 mr0">
                <p className="f5 tr hover-blue">Developed &#38; Maintained by <span className="blue hover-red">Bisakh Mondal.</span></p>
                </div>
            </div>
        </div>
    )
}

export default Footer
